.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.purchasing-page {
  padding: 0.3rem 0.3rem 0;
}
.purchasing-page .purchasing-list .purchasing-item .platform {
  font-size: 0.28rem;
  color: #333;
  height: 0.4rem;
  line-height: 0.4rem;
  margin-bottom: 0.2rem;
}
.purchasing-page .purchasing-list .purchasing-item .platform .line {
  width: 0.04rem;
  height: 0.2rem;
  background: var(--main-color);
  margin-right: 0.2rem;
  border-radius: 0.02rem;
}
.purchasing-page .purchasing-list .purchasing-item .goods-item {
  background: #fff;
  box-shadow: 0 0.06rem 0.4rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.08rem;
  height: 2.2rem;
  margin-bottom: 0.2rem;
}
.purchasing-page .purchasing-list .purchasing-item .goods-item .goods-img {
  width: 2.2rem;
  height: 2.2rem;
  border-bottom-left-radius: 0.08rem;
  border-top-left-radius: 0.08rem;
}
.purchasing-page .purchasing-list .purchasing-item .goods-item .goods-info {
  width: 4.7rem;
  height: 100%;
  padding: 0.24rem 0.1rem 0.2rem 0.2rem;
  /*类型*/
  /*价格*/
}
.purchasing-page .purchasing-list .purchasing-item .goods-item .goods-info .title {
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
}
.purchasing-page .purchasing-list .purchasing-item .goods-item .goods-info .describe {
  font-size: 0.24rem;
  color: #999;
  line-height: 0.34rem;
  margin-bottom: 0.1rem;
}
.purchasing-page .purchasing-list .purchasing-item .goods-item .goods-info .type {
  font-size: 0.22rem;
  color: var(--main-color);
  height: 0.36rem;
  line-height: 0.36rem;
  padding: 0 0.18rem;
  border-radius: 0.26rem;
  border: 0.02rem solid var(--main-color);
  box-sizing: content-box;
}
.purchasing-page .purchasing-list .purchasing-item .goods-item .goods-info .price {
  font-size: 0.32rem;
  color: #333;
  font-weight: bold;
  line-height: 0.4rem;
  margin-top: 0.2rem;
}
.purchasing-page .purchasing-list .purchasing-item .goods-item .goods-info .price .old {
  font-size: 0.24rem;
  color: #999;
  text-decoration: line-through;
  margin-left: 0.12rem;
}
